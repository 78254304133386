<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          About us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">The global oil & gas news source</h2>
          <p>
            Upstream reports on the key oil and gas stories from across the globe. We deliver news on the key players and the latest opportunities in oil, LNG and shale. Stay ahead of the pack with our round-the-clock news service and thought leadership from industry experts. Upstream is an award-winning global oil & gas news provider, offering a weekly edition available both digitally and in print, as well as an unparalleled round-the-clock digital news service.
          </p>
          <p>
            Upstream is described as "invaluable", "excellent" and "the best publication of its type on the market" by senior managers in some of the most respected companies in the industry. Upstream has firmly established itself as the leading source of quality news in the international oil and gas industry.
          </p>
          <p>
            Other respected publications have also taken notice, with the Los Angeles Times stating: "The flamboyant trade weekly Upstream has become a must-read both for its bold irreverent style and its thorough coverage." This was followed by praise from the New York Times: "For energy executives, Upstream has become a must-read, like Advertising Age or Variety or American Lawyer in their respective fields."
          </p>
          <p>
            <a href="https://www.upstreamonline.com/subscription">Subscribe to Upstream today</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
